import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled, { createGlobalStyle, keyframes } from "styled-components";

import OnthewayComponent from "../ontheway/ontheway.component"; // Adjust the path as necessary

const gradientRotate = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const Background = styled.div`
  width: 100%;
  height: 100vh; /* Or adjust as needed */
  background: linear-gradient(45deg, #1d9bf0, #ff0080, #4c00ff, #ff7b00);
  background-size: 400% 400%;
  animation: ${gradientRotate} 15s ease infinite;
`;

const Logo = styled.img`
  width: 20vw; /* Default size using viewport width */
  max-width: 100px; /* Maximum size */

  @media (max-width: 768px) {
    width: 30vw; /* Adjust size for smaller screens */
  }

  @media (max-width: 480px) {
    width: 40vw; /* Further adjust size for very small screens */
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 16px;
`;

class Comingb extends Component {
  constructor(props) {
    super(props);

    //this.state = {
    // isTop: true,
    // };
    //this.onScroll = this.onScroll.bind(this);

    this.state = {
      currentImageIndex: 0,
      imagesLoaded: false,
    };
    this.images = [
      "/images/comingsoon/1.jpg",
      "/images/comingsoon/2.jpg",
      "/images/comingsoon/3.jpg",
      "/images/comingsoon/4.jpg",
      "/images/comingsoon/5.jpg",
    ];

    this.preloadedImages = [];
  }

  state = {
    imageLoaded: false,
  };

  componentDidMount() {
    this.preloadImages(this.images, () => {
      this.setState({ imagesLoaded: true });
      this.interval = setInterval(this.changeImage, 10000);
    });
    //const img = new Image();
    //img.src = process.env.PUBLIC_URL + "/images/comingsoon/1.jpg";
    //img.onload = () => {
    //  this.setState({ imageLoaded: true });
    // };
    // this.interval = setInterval(this.changeImage, 10000);

    // document.addEventListener("scroll", () => {
    //   const isTop = window.scrollY < 100;
    //   if (isTop !== this.state.isTop) {
    //     this.onScroll(isTop);
    //   }
    // });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  preloadImages = (imageUrls, callback) => {
    let loadedCount = 0;
    imageUrls.forEach((url, index) => {
      const img = new Image();
      img.src = `${process.env.PUBLIC_URL}${url}`;
      img.onload = () => {
        loadedCount++;
        this.preloadedImages[index] = img;
        if (loadedCount === imageUrls.length) {
          callback();
        }
      };
    });
  };

  changeImage = () => {
    this.setState((prevState) => ({
      currentImageIndex: (prevState.currentImageIndex + 1) % this.images.length,
    }));
  };

  onScroll(isTop) {
    //this.setState({ isTop });
  }

  render() {
    const { currentImageIndex } = this.state;
    const backgroundImageUrl = `${process.env.PUBLIC_URL}${this.images[currentImageIndex]}`;

    return (
      <Background>
        <div
          style={{
            //paddingTop: 120,
            color: "#ffffff",
            fontWeight: "600",
            textShadow: "1px 1px #000000",
            marginRight: -15,
            marginLeft: -15,
            //backgroundImage: `  linear-gradient(to bottom, rgba(229, 230, 238, 0), 1D9BF0 100%),url(${
            //process.env.PUBLIC_URL + "/images/bg/b1.png"
            //})`,

            //backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 70%),url(${backgroundImageUrl})`,
            //backgroundColor: "#1D9BF0",
            backgroundSize: "cover", // Ensures the background image covers the entire container
            backgroundPosition: "center", // Centers the background image
            paddingBottom: "10px",
            height: "100%",
            minHeight: "100vh",
            //background: "linear-gradient(to top, #000000, #1D9BF0)",
            //backgroundColor: "#1D9BF0",
          }}
        >
          <div className="page-container">
            <LogoContainer>
              <Logo src="/images/logo/fulllogo-3.png" alt="Logo" />
            </LogoContainer>

            <div className="comingHeader">
              <div className="comingHeaderDiv">
                <OnthewayComponent />
              </div>
            </div>
          </div>
        </div>
      </Background>
    );
  }
}

export default Comingb;
