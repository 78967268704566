import React, { Component } from "react";
import { Link } from "react-router-dom";

import OnthewayComponent from "../ontheway/ontheway.component"; // Adjust the path as necessary

class Coming extends Component {
  constructor(props) {
    super(props);

    //this.state = {
    // isTop: true,
    // };
    //this.onScroll = this.onScroll.bind(this);

    this.state = {
      currentImageIndex: 0,
      imagesLoaded: false,
    };
    this.images = [
      "/images/comingsoon/1.jpg",
      "/images/comingsoon/2.jpg",
      "/images/comingsoon/3.jpg",
      "/images/comingsoon/4.jpg",
      "/images/comingsoon/5.jpg",
    ];

    this.preloadedImages = [];
  }

  state = {
    imageLoaded: false,
  };

  componentDidMount() {
    this.preloadImages(this.images, () => {
      this.setState({ imagesLoaded: true });
      this.interval = setInterval(this.changeImage, 10000);
    });
    //const img = new Image();
    //img.src = process.env.PUBLIC_URL + "/images/comingsoon/1.jpg";
    //img.onload = () => {
    //  this.setState({ imageLoaded: true });
    // };
    // this.interval = setInterval(this.changeImage, 10000);

    // document.addEventListener("scroll", () => {
    //   const isTop = window.scrollY < 100;
    //   if (isTop !== this.state.isTop) {
    //     this.onScroll(isTop);
    //   }
    // });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  preloadImages = (imageUrls, callback) => {
    let loadedCount = 0;
    imageUrls.forEach((url, index) => {
      const img = new Image();
      img.src = `${process.env.PUBLIC_URL}${url}`;
      img.onload = () => {
        loadedCount++;
        this.preloadedImages[index] = img;
        if (loadedCount === imageUrls.length) {
          callback();
        }
      };
    });
  };

  changeImage = () => {
    this.setState((prevState) => ({
      currentImageIndex: (prevState.currentImageIndex + 1) % this.images.length,
    }));
  };

  onScroll(isTop) {
    //this.setState({ isTop });
  }

  render() {
    const { currentImageIndex } = this.state;
    const backgroundImageUrl = `${process.env.PUBLIC_URL}${this.images[currentImageIndex]}`;

    return (
      <div
        className="container-fluid"
        style={{
          overflowX: "hidden", // Disable horizontal scrolling
          overflowY: "auto", // Enable vertical scrolling
          //height: "100vh",
        }}
      >
        <div
          className="homepage-bg-color fade-background"
          style={{
            //paddingTop: 120,
            color: "#ffffff",
            fontWeight: "600",
            textShadow: "1px 1px #000000",
            marginRight: -15,
            marginLeft: -15,
            //backgroundImage: `  linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 70%),url(${
            //  process.env.PUBLIC_URL + "/images/comingsoon/5.jpg"
            // })`,

            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 70%),url(${backgroundImageUrl})`,
            backgroundSize: "cover", // Ensures the background image covers the entire container
            backgroundPosition: "center", // Centers the background image
            paddingBottom: "10px",
            height: "100%",
            //minHeight: "50vh",
            //background: "linear-gradient(to top, #000000, #1D9BF0)",
            //backgroundColor: "#1D9BF0",
          }}
        >
          <div className="container hm-main">
            <div style={{ marginBottom: "16px" }}>
              <img
                src="/images/logo/fulllogo-1.png"
                style={{ width: "120px" }}
                alt=""
              />
            </div>

            <div>
              <OnthewayComponent />
            </div>
          </div>
          <div></div>
        </div>
      </div>
    );
  }
}

export default Coming;
