import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  withRouter,
  Switch,
  Redirect,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// Open after the coming soon
import "./style/navbar-top-fixed.css";

import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
// import PNavbar from "./components/private-route/Nav";
// import Landing from "./components/layout/Landing";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";

import PrivateRoute from "./components/private-route/PrivateRoute";
// import Dashboard from "./components/dashboard/Dashboard";

import CreateTodo from "./components/tasks/create-todo.componenet";
import CreateList from "./components/lists/create-list.component";
import CreateGoal from "./components/goals/create-goal.component";
import EditTodo from "./components/tasks/edit-todo.componenet";
import TodoList from "./components/tasks/todo-list.componenet";
import Settings from "./components/general/settings.componenet";
import Me from "./components/general/me.component";
import User from "./components/users/user";
import TasksUser from "./components/tasks/tasks-user";
import GoalsUser from "./components/goals/goals-user.component";
import Ontheway from "./components/ontheway/ontheway.component";
import Home from "./components/home/home.component";
import Coming from "./components/comingsoon/coming.component";
import Comingb from "./components/comingsoon/comingb.component";
import PHome from "./components/home/phome.component";
import About from "./components/general/about.component";
import Community from "./components/community/community.component";
import Upload from "./components/general/fileupload.component";
import Lists from "./components/lists/list.component";
import Goals from "./components/goals/goals.component";
import GoalCats from "./components/goals/goal-category.component";
import Notifications from "./components/notifications/notifications.component";
import EditGoal from "./components/goals/edit-goal.component";
import ContactUs from "./components/contactus/contactus.component";
import QA from "./components/qa/qa.component";
import Careers from "./components/careers/careers.component";
import Privacy from "./components/privacy/privacy.component";
import Tos from "./components/tos/tos.component";

import CSPrivacy from "./components/privacy/csprivacy.component";
import CSTos from "./components/tos/cstos.component";

import Task from "./components/tasks/task.component";
import Goal from "./components/goals/goal.component";
import Confirm from "./components/users/confirm.component";
import Forgot from "./components/general/forgotpass";
//import ReactGA from "react-ga";
import ReactGA from "react-ga4";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Button, Link } from "react-floating-action-button";

// import logo from "./logo.png";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}

//ReactGA.initialize("UA-149074851-1");
//ReactGA.pageview(window.location.pathname + window.location.search);

// Initialize GA4
ReactGA.initialize("G-3VKRXQ973F");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname + window.location.search,
});

console.log(window.location.pathname + window.location.search);

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

class App extends Component {
  constructor(props) {
    super(props);
    //   //this.state = { todos: [] };
    this.closeNav = this.closeNav.bind(this);
  }

  state = {
    cssLoaded: false,
  };

  loadCSSConditionally = () => {
    const { location } = this.props;
    const excludeRoutes = ["/comingsoon"];

    //console.log("loadCSSConditionally");
    //console.log("location.pathname:" + location.pathname);
    //console.log(
    //  "excludeRoutes.includes:" + excludeRoutes.includes(location.pathname)
    // );

    //if (!excludeRoutes.includes(location.pathname)) {
    //  import("./style/navbar-top-fixed.css").then(() => {
    //    this.setState({ cssLoaded: true });
    //  });
    // } else {
    //   this.setState({ cssLoaded: false });
    // }
  };

  componentDidMount() {
    //this.loadCSSConditionally();
  }

  componentDidUpdate(prevProps) {
    //if (prevProps.location.pathname !== this.props.location.pathname) {
    //  this.loadCSSConditionally();
    //}
  }

  closeNav() {
    document.getElementById("navbarCollapse").className = document
      .getElementById("navbarCollapse")
      .className.replace("show", "");
  }

  render() {
    const { location } = this.props;
    //this.loadCSSConditionally();
    //console.log("location.pathname:" + location.pathname);

    return (
      <Provider store={store}>
        <div className="App ">
          {/* <Route exact path="/" component={Dashboard} /> */}

          <Route exact path="/cs/tos" component={CSTos} />
          <Route exact path="/cs/privacy" component={CSPrivacy} />
          {/**  <Route exact path="/comingsoon" component={Coming} />*/}
          <Route exact path="/comingsoon" component={Comingb} />

          {/*** this is on comment for the coming soon period */}

          {/** 
          <Route exact path="/register" component={Register} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/home" component={PHome} />
          <Route exact path="/ontheway" component={Ontheway} />
          <Route exact path="/about" component={About} />
          <Route exact path="/user/confirm/:id" component={Confirm} />
          <Route exact path="/tos" component={Tos} />
          <Route exact path="/privacy" component={Privacy} />
          
          <Route exact path="/careers" component={Careers} />
          <Route exact path="/qa" component={QA} />
          <Route exact path="/contactus" component={ContactUs} />
          <Route exact path="/forgot" component={Forgot} />
*/}
          {/* <Switch>
              <PrivateRoute exact path="/" component={TodoList} />
            </Switch> */}

          <PrivateRoute exact path="/" component={TodoList} />
          <PrivateRoute exact path="/edit/:id" component={EditTodo} />
          <PrivateRoute exact path="/editgoal/:id" component={EditGoal} />
          <PrivateRoute exact path="/create" component={CreateTodo} />
          <PrivateRoute exact path="/create/:id" component={CreateTodo} />
          <PrivateRoute exact path="/createlist" component={CreateList} />
          <PrivateRoute exact path="/creategoal/:id" component={CreateGoal} />
          <PrivateRoute exact path="/upload" component={Upload} />
          <PrivateRoute exact path="/settings" component={Settings} />
          <PrivateRoute exact path="/users/me" component={Me} />
          <PrivateRoute exact path="/users/:id" component={User} />
          <PrivateRoute exact path="/lists" component={Lists} />
          <PrivateRoute exact path="/goals" component={Goals} />
          <PrivateRoute exact path="/goals/:id" component={GoalsUser} />
          <PrivateRoute exact path="/tasks/:id" component={TasksUser} />
          <PrivateRoute exact path="/community" component={Community} />

          <PrivateRoute exact path="/task/:id" component={Task} />
          <PrivateRoute exact path="/goal/:id" component={Goal} />
          <PrivateRoute exact path="/notifications" component={Notifications} />
          <PrivateRoute exact path="/goalcats" component={GoalCats} />
          <PrivateRoute exact path="/dash" component={Home} />

          <Route component={ScrollToTop} />

          <Redirect to="/comingsoon" />
          {/** this is on comment for the coming soon period 
          
          */}
        </div>

        <Footer version="1.0.0" year={2025} />
      </Provider>

      // <Router>
      //   <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      //     <a className="navbar-brand " href="/">
      //       <img src={logo} style={{ width: 50 }} alt="bealyo.com" />
      //     </a>
      //     <Link to="/" className="navbar-brand">
      //       BEALYO
      //     </Link>

      //     <button
      //       className="navbar-toggler"
      //       type="button"
      //       data-toggle="collapse"
      //       data-target="#navbarCollapse"
      //       aria-controls="navbarCollapse"
      //       aria-expanded="false"
      //       aria-label="Toggle navigation"
      //     >
      //       <span className="navbar-toggler-icon" />
      //     </button>
      //     <div className="navbar-collapse collapse" id="navbarCollapse">
      //       <ul className="navbar-nav mr-auto">
      //         <li className="navbar-item">
      //           <Link to="/" className="nav-link" onClick={this.closeNav}>
      //             All Goals <span className="sr-only">(current)</span>
      //           </Link>
      //         </li>
      //         <li className="navbar-item">
      //           <Link to="/create" className="nav-link" onClick={this.closeNav}>
      //             Create Goal
      //           </Link>
      //         </li>
      //         <li className="navbar-item">
      //           <Link
      //             to="/Settings"
      //             className="nav-link"
      //             onClick={this.closeNav}
      //           >
      //             Settings
      //           </Link>
      //         </li>
      //       </ul>
      //       <form className="form-inline mt-2 mt-md-0">
      //         <input
      //           className="form-control mr-sm-2"
      //           type="text"
      //           placeholder="Search"
      //           aria-label="Search"
      //         />
      //         <button
      //           className="btn btn-outline-success my-2 my-sm-0"
      //           type="submit"
      //         >
      //           Search
      //         </button>
      //       </form>
      //     </div>
      //   </nav>
      //   <Route path="/" exact component={TodoList} />
      //   <Route path="/edit/:id" component={EditTodo} />
      //   <Route path="/create" component={CreateTodo} />
      //   <Route path="/Settings" component={Settings} />
      //   <Route component={ScrollToTop} />
      // </Router>
    );
  }
}

export default withRouter(App);
